import styled from "styled-components";
import { Div, Button } from "../../billing/CardTransactions/styles";
import { Link } from "react-router-dom";
import { globalColor, globalFont } from "../../utils/RepeatFunctions/Schedule";

export const Image = styled.img`
  width: ${(p) => (p.width ? p.width : "max-content")};
  height: ${(p) => (p.height ? p.height : "max-content")};
  object-fit: ${(p) => (p.objectFit ? p.objectFit : " contain")};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  cursor: ${(p) => p.cursor && p.cursor};
  display: ${(p) => p.display && p.display};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  font-feature-settings: ${(p) => p.featureSettings && p.featureSettings};
`;

export const Div1 = styled(Div)`
  text-align: ${(p) => p.align && p.align};
  align-items: ${(p) => p.align && p.align};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  border: ${(p) => p.border && p.border};
  width: ${(p) => p.width && p.width};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  display: ${(p) => p.display && p.display};
  box-shadow: ${(p) => p.shadow && p.shadow};
  align-items: ${(p) => p.al && p.al};
  flex-wrap: ${(p) => p.flexWrap && p.flexWrap};
  border-top: ${(p) => p.borderTop && p.borderTop};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  overflow: ${(p) => p.overflow && p.overflow};
`;

export const Button1 = styled(Button)`
  font-family: ${(p) => p.family && p.family};
  font-weight: ${(p) => p.weight && p.weight};
  font-size: ${(p) => p.size && p.size};
  margin-right: ${(p) => p.marginrig && p.marginrig};
  line-height: ${(p) => p.lheight && p.lheight};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  margin: ${(p) => p.margin && p.margin};
  &:focus-visible {
    outline: none;
  }
  border: ${(p) => p.border && p.border};
  &.save_active:active {
    background-color: rgba(44, 123, 229, 0.75);
  }
  &.save_active:disabled {
    background-color: rgba(44, 123, 229, 0.5);
    cursor: not-allowed;
  }
  &.close_active:active {
    background-color: rgba(44, 123, 229, 0.1);
  }
  &.close_active:disabled {
    color: rgba(44, 123, 229, 0.5);
    background-color: #fff;
    cursor: not-allowed;
  }
  &:hover {
    background-color: ${(p) => p.hoverBackColor && p.hoverBackColor};
  }
  &.close_active {
    border: 1px solid ${globalColor};
    background: #fff;
    color: ${globalColor};
  }
`;

export const H3 = styled.h3`
  color: ${(p) => p.color && p.color};
  font-weight: ${(p) => p.weight && p.weight};
  font-family: ${(p) => p.family && p.family};
  font-size: ${(p) => p.size && p.size};
  line-height: ${(p) => p.lheight && p.lheight};
  padding: ${(p) => p.padding && p.padding};
  text-align: ${(p) => p.align && p.align};
  margin: ${(p) => p.margin && p.margin};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  text-align: ${(p) => p.textAlign && p.textAlign};
`;

export const P = styled.p`
  color: ${(p) => p.color && p.color};
  height: ${(p) => p.height && p.height};
  font-weight: ${(p) => p.weight && p.weight};
  font-family: ${(p) => p.family && p.family};
  font-size: ${(p) => p.size && p.size};
  line-height: ${(p) => p.lheight && p.lheight};
  width: ${(p) => p.width && p.width};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  overflow: ${(p) => p.overflow && p.overflow};
  margin: ${(p) => p.margin && p.margin};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  padding: ${(p) => p.padding && p.padding};
`;
export const Table = styled.table`
  margin: ${(p) => p.margin && p.margin};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  width: 100%;
  border-spacing: 0px;
  min-width: ${(p) => p.minWidth && p.minWidth};
  padding: ${(p) => p.padding && p.padding};
  overflow: ${(p) => p.overflow && p.overflow};
  height: ${(p) => p.height && p.height};
  background: ${(p) => p.background && p.background};
  table-layout: ${(p) => p.tableLayout && p.tableLayout};
`;

export const TableHead = styled.thead`
  text-align: left;
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  display: ${(p) => (p.scroll ? "table" : "")};
  width: ${(p) => (p.scroll ? "100%" : "")};
  table-layout: ${(p) => (p.scroll ? "fixed" : p.tableLayout)};
  height: ${(p) => p.height && p.height};

  display: ${(p) => p.display && p.display};
  width: ${(p) => p.width && p.width};
`;
export const Button_1 = styled.button`
  ${(p) => p.height && `height:  ${p.height};`}
  ${(p) => p.width && `width: ${p.width};`}
  ${(p) => p.backgroundColor && `background-color: ${p.backgroundColor};`}
  ${(p) => p.padding && `padding: ${p.padding};`}
  ${(p) => p.radius && `border-radius: ${p.radius};`}
  ${(p) => p.color && `color: ${p.color};`}
  font-family : ${globalFont};
  ${(p) => p.weight && `font-weight : ${p.weight}`};
  ${(p) => p.fontSize && `font-size : ${p.fontSize}`};
  ${(p) => p.border && `border : ${p.border}`};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  padding: ${(p) => p.padding && p.padding};
  margin: ${(p) => p.margin && p.margin};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
`;

export const TableBody = styled.tbody`
  text-align: left;
  table-layout: ${(p) => p.tableLayout && p.tableLayout};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  display: ${(p) => p.display && p.display};
  background-color: ${(props) => props.background};
  overflow-y: ${(p) => (p.scroll ? "scroll" : "")};
  display: ${(p) => (p.scroll ? "block" : "")};
  height: ${(p) => (p.scroll ? p.height : "")};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  background-color: ${(props) => props.background};
`;

export const TableHeadCell = styled.th`
  font-family: ${(p) => (p.fontFamily ? p.fontFamily : globalFont)};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : " 500")};
  font-size: ${(p) => (p.fontSize ? p.fontSize : "14px")};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "17px")};
  color: ${(p) => (p.color ? p.color : globalColor)};
  padding: ${(p) => (p.padding ? p.padding : "12px 0px 11px 0px")};
  width: ${(p) => p.width && p.width};
  text-align: ${(p) => (p.textAlign ? p.textAlign : "left")};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  background: ${({ background }) => background && background};
  border-top-left-radius: ${({ borderTopLeftRadius }) =>
    borderTopLeftRadius && borderTopLeftRadius};
  border-top-right-radius: ${({ borderTopRightRadius }) =>
    borderTopRightRadius && borderTopRightRadius};
  border-right: ${({ borderRight }) => borderRight && borderRight};
  word-break: ${(p) => p.wordBreak && p.wordBreak};
  word-wrap: ${(p) => p.wordWrap && p.wordWrap};
  display: ${(p) => p.display && p.display};
`;
export const TableBodyCell = styled.td`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "14px")};
  align-items: ${(p) => p.alignItems && p.alignItems};
  cursor: ${(p) => p.cursor && p.cursor};
  display: ${(p) => p.display && p.display};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : " 400")};
  font-family: ${(p) => (p.fontFamily ? p.fontFamily : globalFont)};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "17px")};
  color: ${(p) => (p.color ? p.color : " #000000")};
  padding: ${(p) => (p.padding ? p.padding : "19px 0px 20px 0px")};
  text-align: ${(p) => p.textAlign && p.textAlign};
  border-bottom: ${(p) => (p.bodered ? "1px solid #D8E0F0" : "none")};
  border-bottom: ${(p) => (p.borderBotom ? p.borderBotom : "none")};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  width: ${(p) => p.width && p.width};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};
  min-width: ${(p) => p.minWidth && p.minWidth};
  border-right: ${({ borderRight }) => borderRight && borderRight};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  overflow-wrap: ${(p) => p.overFlowWrap && p.overFlowWrap};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  overflow: ${(p) => p.overflow && p.overflow};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  word-wrap: ${(p) => p.wordWrap && p.wordWrap};
  &.vit_font {
    font-size: 14px;
    @media (max-width: 820px) {
      font-size: 10px;
    }
  }
`;
export const TableRow = styled.tr`
  font-family: ${(p) => (p.fontFamily ? p.fontFamily : globalFont)};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  border-bottom: ${(p) => (p.bodered ? "1px solid #D8E0F0" : "none")};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  text-align: left;
  background-color: ${(props) => props.background};
  cursor: ${(p) => p.cursor && p.cursor};
  border-bottom: ${(p) => (p.borderbottom ? "1px solid #D8E0F0" : "none")};
  height: ${(p) => (p.height ? p.height : "")};
  &:last-child ${TableBodyCell} {
    border-bottom: ${(p) => (p.bbtom ? "1px solid #D8E0F0" : "none")};
  }
  &:last-child {
    border-bottom: ${(p) => p.lastchild && "none"};
  }
  display: ${(p) => (p.scroll ? "table" : p.display)};
  width: ${(p) => (p.scroll ? "100%" : p.width)};
  table-layout: ${(p) => (p.scroll ? "fixed" : p.tableLayout)};
  justify-content: ${(p) => p.justifyCont && p.justifyCont};
  &.hover_row:hover {
    overflow-x: hidden;
    background-color: #fafafa;
    //margin: 5px;
    // margin-right:4px;
  }
  &:hover {
    background-color: ${(p) => p.bghovercolor && p.bghovercolor};
  }
`;

export const TxtSpan = styled.span`
  font-size: 12px;
  line-height: 17px;
  font-family: ${globalFont};
  font-weight: 600;
`;

export const PaymentStatus = styled(TxtSpan)`
  width: 72px;
  color: #20c9ac;
  background: rgba(32, 201, 172, 0.1);
  border-radius: 4px;
  padding: 2px 8px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: ${(p) => (p.width ? p.width : "100vw")};
  height: ${(p) => (p.height ? p.height : "100vh")};
  display: ${(p) => (p.show === true ? "block" : "none")};
  background-color: rgba(102, 112, 133, 0.4);
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => p.alignItems && p.alignItems};
`;

export const ModalContainer = styled.div`
  overflow-x: hidden;
  overflow-y: ${(p) => (p.overflowY ? p.overflowY : "auto")};
  outline: 0;
  display: flex;
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  align-items: center;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${(p) => p.padding && p.padding};
  margin: ${(p) => p.margin && p.margin};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  border-bottom: ${(p) => (p.borderLess ? "none" : "1px solid #d8e0f0")};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  color: ${(p) => p.color && p.color};
`;

export const ModalBody = styled.div`
  padding: ${(p) => p.padding && p.padding};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  color: ${(p) => p.color && p.color};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
`;

export const ModalDialog = styled.div`
  width: ${(p) => (p.width ? p.width : "544px")};
  min-height: ${(p) => p.mheight && p.mheight};
  background: #ffffff;
  position: relative;
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  border: ${(p) => p.border && p.border};
  margin: ${(p) => p.margin && p.margin};
  position: ${(p) => p.position && p.position};
  top: ${(p) => p.top && p.top};
  transition: transform 3s;
  animation: ${(p) =>
    p.animattion ? "show_1 0.1s ease-in-out" : "show_2 0.1s ease-in-out"};
  @keyframes show_1 {
    from {
      transform: translateY(-50px);
    }
    to {
      transform: translateY(0);
    }
  }
  @keyframes show_2 {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-50px);
    }
  }
`;

export const ModalDiag = styled(ModalDialog)`
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  position: relative;
`;

export const PageTitle = styled.h1`
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight || "700"};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  display: flex;
  align-items: ${(p) => (p.alignItem ? p.alignItem : "center")};
  color: ${(p) => (p.color ? p.color : "#232934")};
  padding: ${(p) => p.padding && p.padding};
  margin: ${(p) => p.margin && p.margin};
`;
export const SpanCont = styled.span`
  font-family: ${(p) => (p.fontFamily ? p.fontFamily : globalFont)};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "16px")};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  color: ${(p) => p.color && p.color};
  cursor: ${(p) => p.cursor && p.cursor};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  width: ${(p) => p.width && p.width};
  position: ${(p) => p.position && p.position};
  text-align: ${(p) => p.textAlign && p.textAlign};
  align-items: ${(p) => p.alignItem && p.alignItem};
  padding-left: ${(p) => p.pl && p.pl};
  padding-top: ${(p) => p.pt && p.pt};
  cursor: ${(p) => p.cursor && p.cursor};
`;
export const Text = styled.p`
  color: ${(p) => (p.color ? p.color : "black")};
  font-family: ${(p) => (p.fontFamily ? p.fontFamily : globalFont)};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "19px")};
  width: ${(p) => (p.width ? p.width : "100%")};

  text-align: ${(p) => (p.textAlign ? p.textAlign : "center")};
  margin-top: ${(p) => (p.mt ? p.mt : "10px")};
  margin-bottom: ${(p) => (p.mb ? p.mb : "10px")};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-size: ${(p) => p.fontSize && p.fontSize};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  overflow-wrap: ${(p) => p.overflowWrap && p.overflowWrap};
`;

export const Div_1 = styled.div`
  ${(p) => p.display && `display : ${p.display};`}
  ${(p) => p.position && `position : ${p.position};`}
  height : ${(p) => p.height || "auto"};
  width: ${(p) => {
    if (p.widthOffset) {
      return `calc(100% - ${p.widthOffset})`;
    } else {
      return p.width || "auto";
    }
  }};
  ${(p) => p.margin && `margin: ${p.margin};`};
  ${(p) => p.padding && `padding: ${p.padding};`};
  ${(p) => p.border && `border: ${p.border};`};
  ${(p) => p.boxShadow && `box-shadow: ${p.boxShadow};`};
  ${(p) => p.borderRadius && `border-radius: ${p.borderRadius};`};

  background-color: ${(p) => p.backColor || "white"};
  color: ${(p) => p.color || "black"};

  ${(p) => p.borderBox && "box-sizing : border-box;"}
  ${(p) => {
    if (p.flexCenterAll) {
      return `display : flex;
      align-items : center;
      justify-content : center;`;
    } else if (p.flexCenterVertical) {
      return `display : flex;
      align-items : center;
      `;
    } else if (p.flexCenterHorizontal) {
      return `display : flex;
    justify-content : center;
    `;
    } else if (p.flexCenterBetween) {
      return `display : flex;
    align-items : start;
    justify-content : space-between;
    `;
    }
  }}
`;
export const Span = styled.span`
  color: ${(p) => p.color && p.color};
  font-weight: ${(p) => p.weight && p.weight};
  font-family: ${(p) => p.family && p.family};
  font-size: ${(p) => p.size && p.size};
  line-height: ${(p) => p.lheight && p.lheight};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin: ${(p) => p.margin && p.margin};
  cursor: ${(p) => p.cursor && p.cursor};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-right: ${(p) => p.marginright && p.marginright};
  text-decoration: ${(p) => p.textDecoration && p.textDecoration};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  background: ${(p) => p.background && p.background};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  display: ${(p) => p.display && p.display};
  ${(flexCenterAll) =>
    flexCenterAll &&
    `
    display : inline-flex;
    align-items : center;
    justify-content : center;    
    `}
  cursor: ${(p) => p.cursor && p.cursor};
  &.custom-radio {
    left: -9px;
    top: 6px;
    cursor: pointer;
    width: 18px;
    height: 18px;
    border: 2px solid ${globalColor};
    border-radius: 50%;
    display: inline-block;
    position: relative;
  }
  &.custom-radio::after {
    content: "";
    width: 10px;
    height: 10px;
    background: ${globalColor};
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s;
  }
`;
export const LinK = styled(Link)`
  color: ${(p) => p.color && p.color};
  font-weight: ${(p) => p.weight && p.weight};
  font-family: ${(p) => p.family && p.family};
  font-size: ${(p) => p.size && p.size};
  line-height: ${(p) => p.lheight && p.lheight};
  text-decoration: ${(p) => p.textDecoration && p.textDecoration};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
`;

export const ModalFooter = styled.div`
  border-top: ${(p) => (p.borderTop ? p.borderTop : " 1px solid #cbd5e0")};
  height: 72px;
  padding: 16px 0px 24px 20px;
  display: flex;
  align-items: center;
`;

/*Ahamed_5/5/23*/
export const TableHeadClinic = styled(TableHeadCell)`
  color: ${(p) => p.color && p.color};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "18px !important")};
  border-right: 1px solid #e2e8ed !important;
  &:last-child {
    border-right: none !important;
  }
`;

export const TableBodyCellCliResp = styled(TableBodyCell)`
  padding: 6px 16px 6px 16px !important;
`;
/*Ahamed_5/5/23*/
export const ModalDiagClient = styled(ModalDialog)`
  width: ${(p) => (p.width ? p.width : "544px")};
  min-height: ${(p) => p.mheight && p.mheight};
  background: #ffffff;
  position: relative;
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
  border-radius: 12px;
  animation: none;
  /* animation: ${(p) =>
    p.animattion ? "show_1 0.1s ease-in-out" : "show_2 0.1s ease-in-out"}; */

  @keyframes show_1 {
    from {
      transform: translateY(-50px);
    }
    to {
      transform: translateY(0);
    }
  }
  @keyframes show_2 {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-50px);
    }
  }
`;
