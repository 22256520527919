import styled from "styled-components";
import { AiOutlinePaperClip } from "react-icons/ai";
import { BsFillImageFill, BsFillFileEarmarkFill } from "react-icons/bs";
import { VscMention } from "react-icons/vsc";
import { BsXLg } from "react-icons/bs";
import { FlexBetween, PageTitle } from "../../StyledComponents";
import { BsDownload } from "react-icons/bs";
import { globalColor, globalFont } from "../utils/RepeatFunctions/Schedule";

export const InputBox = styled.input`
  padding: 8px 12px;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  outline: none;
  font-family: ${globalFont};
`;

export const MessageContiner = styled.div`
  display: flex;
  align-items: flex-start;

  justify-content: space-between;
  height: ${(p) => p.height && p.height};
  background: linear-gradient(to right, #fff 0%, #fff 37%, #fff 37%, #fff 100%);
  padding: ${(p) => p.padding && p.padding};
  margin: ${(p) => p.margin && p.margin};
`;

export const SearchInputText = styled.input`
  background: none;
  border: none;
  box-shadow: none;
  font-size: 14px;
  padding-left: 5px;
  outline-offset: -26px;

  @media (max-width: 1070px) {
    width: 112px;
  }
`;

export const Attach = styled(AiOutlinePaperClip)`
  margin-top: 15px;
  margin-right: 5px;
  width: 25px;
  height: 25px;
  color: #6d5dd3;
  cursor: pointer;
`;

export const AtSymbol = styled(VscMention)`
  margin-top: 15px;
  margin-right: 5px;
  width: 25px;
  height: 25px;
  color: #3f8cff;
  cursor: pointer;
`;

export const TypeText = styled.input`
  height: 30px;
  background-color: rgba(0, 0, 0, 0);
  outline: none;
  border: none;
  margin-left: 20px;
  margin-top: 10px;
  min-width: 74%;
`;

export const Close = styled(BsXLg)`
  margin-left: auto;
  cursor: pointer;
  color: ${globalColor};
  margin-right: 34px;
`;

export const ViewImage = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const DowloadBtn = styled(BsDownload)`
  cursor: pointer;
  color: ${globalColor};
  font-weight: bold;
  margin-left: 20px;
`;

export const ThumbImage = styled(BsFillImageFill)`
  margin-top: 2.5px;
`;
export const ThumbDoc = styled(BsFillFileEarmarkFill)`
  margin-top: 2.5px;
`;

export const Modal = styled.div`
  position: ${(p) => (p.position ? p.position : "fixed")};
  top: 0;
  left: 0;
  right: ${(p) => p.right && p.right};
  bottom: ${(p) => p.bottom && p.bottom};
  z-index: 1040;
  width: ${({ width }) => (width ? width : "100vw")};
  height: ${({ height }) => (height ? height : "100vh")};
  display: ${(p) => (p.show ? "flex " : " none")};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  background-color: rgba(102, 112, 133, 0.4);
  cursor: ${(p) => p.cursor && p.cursor};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
`;

export const ModalContainer = styled.div`
  position: ${(p) => (p.position ? p.position : "fixed")};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  z-index: 1055;
  overflow: ${(p) => p.overflow && p.overflow};
  overflow-x: ${(p) => (p.overflowX ? p.overflowX : "hidden")};
  overflow-y: ${(p) => (p.overflowY ? p.overflowY : "auto")};
  outline: 0;
  display: ${(p) => p.display && p.display};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  background-color: ${(p) =>
    p.backgroundColor ? p.backgroundColor : " #ffffff"};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  cursor: auto;
  border: ${(p) => p.border && p.border};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  padding: ${(p) => p.padding && p.padding};
`;

export const ModalTitle = styled.h4`
  line-height: ${(p) => p.lineHight && p.lineHight};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  color: ${(p) => p.color && p.color};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  font-style: ${(p) => (p.fontStyle ? p.fontStyle : p.fontStyle)};
  margin: ${(p) => p.margin && p.margin};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-right: ${(p) => p.marginRight && p.marginRight};
`;

export const Image = styled.img`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  object-fit: ${(props) => props.objectFit && props.objectFit};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  display: ${(p) => p.display && p.display};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  position: ${(p) => p.position && p.position};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  z-index: ${(p) => p.zIndex && p.zIndex};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  right: ${(p) => p.right && p.right};
  cursor: ${(p) => p.cursor && p.cursor};
  text-align: ${(p) => p.textAlign && p.textAlign};
  transition: ${(props) => props.transition && props.transition};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
`;
export const Div = styled.div`
  display: ${(p) => p.display && p.display};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  position: ${(p) => p.position && p.position};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  margin: ${(p) => p.margin && p.margin};
  z-index: ${(p) => p.zIndex && p.zIndex};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  color: ${(p) => p.color && p.color};
  border: ${(p) => p.border && p.border};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  padding: ${(p) => p.padding && p.padding};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => p.alignItems && p.alignItems};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};

  &.voice-note-animation {
    width: 385px;
    height: 6px;
    background-color: #5f595c;
    position: relative;
    overflow: hidden;
  }

  &.voice-note-animation::before {
    content: "";
    position: absolute;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent 0%,
      transparent 50%,
      ${globalColor} 50%,
      ${globalColor} 100%
    );
    transform: translateX(-100%);
    animation: wave-animation 1.5s linear infinite;
  }

  @keyframes wave-animation {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  &.paused-animation::before {
    animation-play-state: paused;
  }
`;
export const ChatTabTitle = styled.div`
  position: relative;
  display: flex;
  &::after {
    content: "";
    position: absolute;
    width: ${(p) => (p.Active ? "7em" : "7.7em")};
    height: 2px;
    background: ${globalColor};
    display: block;
    margin-top: 13.5%;
    transform: ${(p) => (p.Active ? "translateX(143px)" : "translateX(0px)")};
    transition: transform 1s;
  }
`;
