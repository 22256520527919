import React, { useState, useEffect } from "react";
import { SubNavItem, ListItem, UL, Div } from "./styles";
import { useLocation } from "react-router-dom";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import UnLoadScripts from "../popups/unLoadScript";
import { GetNav } from "../../StateManagement/Reducers/ScheduleState";
import { useDispatch } from "react-redux";
import { globalFont } from "../utils/RepeatFunctions/Schedule";

var CryptoJS = require("crypto-js");

const Encode_Decode_JSON = {
  stringify: function (DecodingVal) {
    var Data_Val = {
      Encode: DecodingVal.ciphertext.toString(CryptoJS.enc.Base64),
    };
    if (DecodingVal.iv) Data_Val.Decentral = DecodingVal.iv.toString();
    if (DecodingVal.salt) Data_Val.EndCode = DecodingVal.salt.toString();
    return JSON.stringify(Data_Val);
  },
  parse: function (StrVal) {
    var Data_Val = JSON.parse(StrVal);
    var DecodingVal = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(Data_Val.Encode),
    });
    if (Data_Val.Decentral)
      DecodingVal.Decentral = CryptoJS.enc.Hex.parse(Data_Val.Decentral);
    if (Data_Val.EndCode)
      DecodingVal.salt = CryptoJS.enc.Hex.parse(Data_Val.EndCode);

    return DecodingVal;
  },
};

const Decrypt_Value = (Val, key) => {
  return JSON.parse(
    CryptoJS.AES.decrypt(Val, key, {
      format: Encode_Decode_JSON,
    }).toString(CryptoJS.enc.Utf8)
  );
};
const SettingsNav = () => {
  const [openMenu, setOpenMenu] = useState(null);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  var owner_id = Decrypt_Value(localStorage.getItem("owner_id"), "vozo");
  const accountRoutes = [
    "settingsmyprofile",
    "settingpracticeinfo",
    "Users",
    "Roles",
    "security",
    "myplan",
    "securitydocument",
    "timezonesettings",
  ];
  const billingRoutes = [
    "settingbilling",
    "settingservice",
    "payersearch",
    "lab",
  ];
  const schedulingRoutes = [
    "calendersettings",
    "appointmentreminder",
    "patientportal",
    "notificationsettings",
  ];
  const administrationRoutes = ["Inventory", "ERXsettings"];
  const toggleItem = (title) => {
    setOpenMenu(openMenu === title ? null : title);
  };
  const currentItem = splitLocation[2];
  const dispatch = useDispatch();

  const handleInputChange = (iconName) => (e) => {
    dispatch(GetNav(iconName));
  };
  useEffect(() => {
    if (currentItem) {
      if (accountRoutes.includes(currentItem)) {
        setOpenMenu("account");
      } else if (billingRoutes.includes(currentItem)) {
        setOpenMenu("billing");
      } else if (schedulingRoutes.includes(currentItem)) {
        setOpenMenu("scheduling");
      } else if (administrationRoutes.includes(currentItem)) {
        setOpenMenu("administration");
      }
    }
  }, []);

  useEffect(() => {
    UnLoadScripts();
  }, []);
  return (
    <Div width="100%" paddingLeft="0.86em" margin="24px 0 0 16px">
      <Div
        cursor="pointer"
        display="flex"
        fontWeight="600"
        fontSize="14px"
        color={openMenu === "account" ? "#2E2E2E" : "rgba(46, 46, 46, 0.50)"}
        fontFamily={globalFont}
        svgFontSize="16px"
        svgMarginLeft="8px"
        svgMarginTop="4px"
      >
        <Div onClick={() => toggleItem("account")}>Account settings</Div>
        {openMenu === "account" ? <FiChevronUp /> : <FiChevronDown />}
      </Div>
      {openMenu === "account" && (
        <UL
          textAlign="left"
          width="100%"
          listStyleType="none"
          padding="0"
          margin="0"
        >
          <ListItem marginTop="16px" onClick={handleInputChange("iconopen")}>
            <SubNavItem
              to="/settings/settingsmyprofile"
              id="setting_nav_profile"
              exact
            >
              My Profile
            </SubNavItem>
          </ListItem>
          <ListItem marginTop="16px" onClick={handleInputChange("iconopen")}>
            <SubNavItem
              to="/settings/settingpracticeinfo"
              exact
              id="setting_nav_facility"
            >
              Facility Information
            </SubNavItem>
          </ListItem>
          <ListItem marginTop="16px" onClick={handleInputChange("iconopen")}>
            <SubNavItem to="/settings/Users" exact id="setting_nav_users">
              User
            </SubNavItem>
          </ListItem>
          <ListItem marginTop="16px" onClick={handleInputChange("iconopen")}>
            <SubNavItem to="/settings/Roles" exact>
              Roles and Permissions
            </SubNavItem>
          </ListItem>
          <ListItem marginTop="16px" onClick={handleInputChange("iconopen")}>
            <SubNavItem to="/settings/security" exact>
              Security
            </SubNavItem>
          </ListItem>
          {(owner_id === null || owner_id === " ") && (
            <ListItem marginTop="16px" onClick={handleInputChange("iconopen")}>
              <SubNavItem to="/settings/myplan" exact id="setting_nav_myplan">
                My plan
              </SubNavItem>
            </ListItem>
          )}
          <ListItem marginTop="16px" onClick={handleInputChange("iconopen")}>
            <SubNavItem
              to="/settings/securitydocument"
              exact
              id="setting_nav_document"
            >
              Documents
            </SubNavItem>
          </ListItem>
          <ListItem marginTop="16px" onClick={handleInputChange("iconopen")}>
            <SubNavItem
              to="/settings/timezonesettings"
              exact
              id="setting_nav_timezone"
            >
              Time Zone Settings
            </SubNavItem>
          </ListItem>
        </UL>
      )}
      <Div
        cursor="pointer"
        display="flex"
        fontWeight="600"
        fontSize="14px"
        color={openMenu === "billing" ? "#2E2E2E" : "rgba(46, 46, 46, 0.50)"}
        fontFamily={globalFont}
        svgFontSize="16px"
        svgMarginLeft="8px"
        svgMarginTop="4px"
        marginTop="24px"
      >
        <Div onClick={() => toggleItem("billing")} id="setting_bill_service">
          Billing and Services
        </Div>
        {openMenu === "billing" ? <FiChevronUp /> : <FiChevronDown />}
      </Div>
      {openMenu === "billing" && (
        <UL
          textAlign="left"
          width="100%"
          listStyleType="none"
          padding="0"
          margin="0"
        >
          <ListItem marginTop="16px" onClick={handleInputChange("iconopen")}>
            <SubNavItem to="/settings/settingbilling" exact>
              Billing
            </SubNavItem>
          </ListItem>
          <ListItem marginTop="16px" onClick={handleInputChange("iconopen")}>
            <SubNavItem
              to="/settings/settingservice"
              exact
              id="setting_nav_services"
            >
              Services
            </SubNavItem>
          </ListItem>
          <ListItem marginTop="16px" onClick={handleInputChange("iconopen")}>
            <SubNavItem to="/settings/payersearch" exact id="setting_nav_payer">
              Payer Search
            </SubNavItem>
          </ListItem>
          <ListItem marginTop="16px" onClick={handleInputChange("iconopen")}>
            <SubNavItem to="/settings/lab" exact id="setting_nav_lab">
              Lab
            </SubNavItem>
          </ListItem>
        </UL>
      )}
      <Div
        cursor="pointer"
        display="flex"
        fontWeight="600"
        fontSize="14px"
        color={openMenu === "scheduling" ? "#2E2E2E" : "rgba(46, 46, 46, 0.50)"}
        fontFamily={globalFont}
        svgFontSize="16px"
        svgMarginLeft="8px"
        svgMarginTop="4px"
        marginTop="24px"
      >
        <Div
          onClick={() => toggleItem("scheduling")}
          id="setting_nav_scheduling"
        >
          Scheduling
        </Div>
        {openMenu === "scheduling" ? <FiChevronUp /> : <FiChevronDown />}
      </Div>
      {openMenu === "scheduling" && (
        <UL
          textAlign="left"
          width="100%"
          listStyleType="none"
          padding="0"
          margin="0"
        >
          <ListItem marginTop="16px" onClick={handleInputChange("iconopen")}>
            <SubNavItem
              to="/settings/calendersettings"
              exact
              id="setting_nav_calendersetting"
            >
              Calendar Settings
            </SubNavItem>
          </ListItem>
          <ListItem marginTop="16px" onClick={handleInputChange("iconopen")}>
            <SubNavItem
              to="/settings/appointmentreminder"
              exact
              id="setting_nav_reminder"
            >
              Appointment Reminders
            </SubNavItem>
          </ListItem>
          {(owner_id === null || owner_id === " ") && (
            <ListItem marginTop="16px" onClick={handleInputChange("iconopen")}>
              <SubNavItem to="/settings/patientportal/all" exact>
                Patient Portal Settings
              </SubNavItem>
            </ListItem>
          )}
          <ListItem marginTop="16px" onClick={handleInputChange("iconopen")}>
            <SubNavItem to="/settings/notificationsettings" exact>
              Notification Settings
            </SubNavItem>
          </ListItem>
        </UL>
      )}
      <Div
        cursor="pointer"
        display="flex"
        fontWeight="600"
        fontSize="14px"
        color={
          openMenu === "administration" ? "#2E2E2E" : "rgba(46, 46, 46, 0.50)"
        }
        fontFamily={globalFont}
        svgFontSize="16px"
        svgMarginLeft="8px"
        svgMarginTop="4px"
        marginTop="24px"
      >
        <Div
          onClick={() => toggleItem("administration")}
          id="setting_nav_scheduling"
        >
          Administration
        </Div>
        {openMenu === "administration" ? <FiChevronUp /> : <FiChevronDown />}
      </Div>
      {openMenu === "administration" && (
        <UL
          textAlign="left"
          width="100%"
          listStyleType="none"
          padding="0"
          margin="0"
        >
          <ListItem marginTop="16px" onClick={handleInputChange("iconclose")}>
            <SubNavItem to="/settings/Inventory" exact id="setting_nav_erx">
              Inventory
            </SubNavItem>
          </ListItem>
          <ListItem marginTop="16px" onClick={handleInputChange("iconopen")}>
            <SubNavItem to="/settings/ERXsettings" exact id="setting_nav_erx">
              eRx Settings
            </SubNavItem>
          </ListItem>
        </UL>
      )}
    </Div>
  );
};

export default SettingsNav;
