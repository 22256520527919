import React from "react";
import { SubNavItem, ListItem, Div, SubMenu } from "./styles";
import { GetNav } from "../../StateManagement/Reducers/ScheduleState";
import { useDispatch } from "react-redux";

const BillingNav = () => {
  const dispatch = useDispatch();

  const handleInputChange = (iconName) => (e) => {
    dispatch(GetNav(iconName));
  };
  return (
    <Div width="100%" paddingLeft="0.86em">
      <SubMenu textAlign="left" width="100%">
        <ListItem
          padding="8px"
          margin="8px 0 0 0"
          onClick={handleInputChange("iconclose")}
        >
          <SubNavItem to="/billing/insuranceclaim">Insurance Claims</SubNavItem>
        </ListItem>
        <ListItem
          padding="8px"
          margin="0"
          onClick={handleInputChange("iconopen")}
        >
          <SubNavItem to="/billing/patientpayment" exact>
            Patient Payments
          </SubNavItem>
        </ListItem>
        <ListItem
          padding="8px"
          margin="0"
          onClick={handleInputChange("iconclose")}
        >
          <SubNavItem to="/billing/patientstatement" exact>
            Patient Statements
          </SubNavItem>
        </ListItem>
        <ListItem
          padding="8px"
          margin="0"
          onClick={handleInputChange("iconopen")}
        >
          <SubNavItem to="/billing/patientbalance" exact>
            Patient Ledger
          </SubNavItem>
        </ListItem>
        <ListItem
          padding="8px"
          margin="0"
          onClick={handleInputChange("iconclose")}
        >
          <SubNavItem to="/billing/feeschedule" exact>
            Fee Schedule
          </SubNavItem>
        </ListItem>
        <ListItem
          padding="8px"
          margin="0"
          onClick={handleInputChange("iconclose")}
        >
          <SubNavItem to="/billing/eob" exact>
            EOB
          </SubNavItem>
        </ListItem>
        <ListItem
          padding="8px"
          margin="0"
          onClick={handleInputChange("iconopen")}
        >
          <SubNavItem to="/billing/cardtransactions" exact>
            Card Transactions
          </SubNavItem>
        </ListItem>
        <ListItem
          padding="8px"
          margin="0"
          onClick={handleInputChange("iconopen")}
        >
          <SubNavItem to="/billing/era" exact>
            ERA
          </SubNavItem>
        </ListItem>
      </SubMenu>
    </Div>
  );
};

export default BillingNav;
